import * as React from 'react';
import clsx from 'clsx';

export interface EmailIconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  iconSize?: 'XL' | 'L' | 'M' | 'S' | 'XS';
}

export const EmailIcon = ({ iconSize, className, ...otherProps }: EmailIconProps) => {
  const svgSizeClassName =
    iconSize === 'XL'
      ? 'w-[2.5rem] h-[2.5rem]'
      : iconSize === 'L'
      ? 'w-[2rem] h-[2rem]'
      : iconSize === 'M'
      ? 'w-[1.5rem] h-[1.5rem]'
      : iconSize === 'S'
      ? 'w-[1.25rem] h-[1.25rem]'
      : 'w-[1rem] h-[1rem]';
  return (
    <svg
      className={clsx(svgSizeClassName, className)}
      {...otherProps}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.334 2.6665H2.66732C1.93094 2.6665 1.33398 3.26346 1.33398 3.99984V11.9998C1.33398 12.7362 1.93094 13.3332 2.66732 13.3332H13.334C14.0704 13.3332 14.6673 12.7362 14.6673 11.9998V3.99984C14.6673 3.26346 14.0704 2.6665 13.334 2.6665Z"
        {...otherProps}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6673 4.6665L8.68732 8.4665C8.4815 8.59545 8.24353 8.66384 8.00065 8.66384C7.75777 8.66384 7.5198 8.59545 7.31398 8.4665L1.33398 4.6665"
        {...otherProps}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
