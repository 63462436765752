import * as React from 'react';
import clsx from 'clsx';

export interface ArrowCornerIconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  iconSize?: 'XL' | 'L' | 'M' | 'S' | 'XS';
}

export const ArrowCornerIcon = ({ iconSize, className, ...otherProps }: ArrowCornerIconProps) => {
  const svgSizeClassName =
    iconSize === 'XL'
      ? 'w-[2.5rem] h-[2.5rem]'
      : iconSize === 'L'
      ? 'w-[2rem] h-[2rem]'
      : iconSize === 'M'
      ? 'w-[1.5rem] h-[1.5rem]'
      : iconSize === 'S'
      ? 'w-[1.25rem] h-[1.25rem]'
      : 'w-[1rem] h-[1rem]';
  return (
    <svg
      className={clsx(svgSizeClassName, className)}
      {...otherProps}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.11133 11.8889L11.8891 4.11108M11.8891 4.11108H4.11133M11.8891 4.11108V11.8889"
        {...otherProps}
        stroke-width="1.2"
        stroke-linejoin="round"
      />
    </svg>
  );
};
