import * as React from 'react';
import clsx from 'clsx';

export interface DocCrashIconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  iconSize?: 'XL' | 'L' | 'M' | 'S' | 'XS';
}

export const DocCrashIcon = ({ iconSize, className, ...otherProps }: DocCrashIconProps) => {
  const svgSizeClassName =
    iconSize === 'XL'
      ? 'w-[2.5rem] h-[2.5rem]'
      : iconSize === 'L'
      ? 'w-[2rem] h-[2rem]'
      : iconSize === 'M'
      ? 'w-[1.5rem] h-[1.5rem]'
      : iconSize === 'S'
      ? 'w-[1.25rem] h-[1.25rem]'
      : 'w-[1rem] h-[1rem]';
  return (
    <svg
      className={clsx(svgSizeClassName, className)}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}>
      <path
        d="M8.125 3.5H19.5L26.5 11V16L23.5 14.5L18.5 19L15.5 16L12.5 20L9.5 17L5.5 19V6.2C5.5 5.48392 5.77656 4.79716 6.26884 4.29081C6.76113 3.78446 7.42881 3.5 8.125 3.5Z"
        stroke-width="2"
        stroke-linejoin="round"
        {...otherProps}
      />
      <path
        d="M6.26884 27.7092C5.77656 27.2028 5.5 26.5161 5.5 25.8V24L9.5 22L12.5 25L15.5 21L18.5 24L23.5 19.5L26.5 21V25.8C26.5 26.5161 26.2234 27.2028 25.7312 27.7092C25.2389 28.2155 24.5712 28.5 23.875 28.5H8.125C7.42881 28.5 6.76113 28.2155 6.26884 27.7092Z"
        stroke-width="2"
        stroke-linejoin="round"
        {...otherProps}
      />
      <path
        d="M19.5 4V11H26"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...otherProps}
      />
    </svg>
  );
};
