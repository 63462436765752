import * as React from 'react';
import clsx from 'clsx';
import { Typography } from 'core/components/Typography';
import ReviewerRegistrationData from 'features/registration/reviewer/data/reviewer.registration.json';
import { Button } from 'core/components/Button';
import { Field } from 'core/components/Field';
import { ReviewerRegistrationForm, forms } from '../../react_hook_form/constants';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from 'core/components/Checkbox';
import { useReviewerRegistrationMutationAddUser } from '../../react_query/hooks/useMutationAddUser.registration.reviewer';
import { Alert } from 'core/components/Alert';

export const PersonalInformationReviewerRegistration = () => {
  const { mutate: addUser } = useReviewerRegistrationMutationAddUser();
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useFormContext<ReviewerRegistrationForm>();

  const handleRegisterReviewer = (e: React.MouseEvent<HTMLButtonElement>) => {
    addUser();
  };

  const handleChangeContactNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value.length) {
      setError(forms.personal_information.contact_number, {
        message: ReviewerRegistrationData.personal_information.errors.required
      });
    } else {
      clearErrors(forms.personal_information.contact_number);
    }
    setValue(forms.personal_information.contact_number, e.currentTarget.value);
  };

  const handleChangeLinkedin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(forms.personal_information.linkedin, e.currentTarget.value);
  };

  const handleSelect = (data: { id: string; name: string }) => {
    const payload = !(
      watch(forms.personal_information.interest) as { id: string; name: string }[]
    ).filter((item) => item.id === data.id).length
      ? [...(watch(forms.personal_information.interest) as { id: string; name: string }[]), data]
      : (watch(forms.personal_information.interest) as { id: string; name: string }[]).filter(
          (item) => item.id !== data.id
        );
    setValue(forms.personal_information.interest, payload);
  };

  const isButtonDisabled = !(watch(forms.personal_information.contact_number) as string)?.length;
  return (
    <div
      className={clsx(
        'grid grid-cols-1 place-items-center place-content-center gap-[2.5rem]',
        'w-[434px]'
      )}>
      <Alert
        isOpen={watch(forms.alert.is_open) as boolean}
        message={watch(forms.alert.message) as string}
      />
      <div
        className={clsx(
          'grid grid-cols-1 place-items-center place-content-center gap-[0.25rem]',
          'w-full'
        )}>
        <Typography
          variant={'Heading'}
          size={'H1'}
          color={'text-grayscale-90'}
          className={'text-center'}>
          {ReviewerRegistrationData.personal_information.title}
        </Typography>
        <Typography variant={'Regular'} size={'M'} color={'text-grayscale-60'}>
          {ReviewerRegistrationData.personal_information.description}
        </Typography>
      </div>

      {/* form */}
      <div
        className={clsx(
          'grid grid-cols-1 items-start content-start justify-center justify-items-center gap-[1.25rem]',
          'w-full max-w-[350px]'
        )}>
        <Field
          key={ReviewerRegistrationData.personal_information.contact_number.name}
          mainLabel={ReviewerRegistrationData.personal_information.contact_number.mainLabel}
          name={forms.personal_information.contact_number}
          secondaryLabel={
            ReviewerRegistrationData.personal_information.contact_number.secondaryLabel
          }
          placeholder={ReviewerRegistrationData.personal_information.contact_number.placeholder}
          variant={!!errors[forms.personal_information.contact_number] ? 'error' : 'default'}
          value={watch(forms.personal_information.contact_number) as string}
          onChange={handleChangeContactNumber}
          helperText={errors[forms.personal_information.contact_number]?.message as string}
        />

        <Field
          key={ReviewerRegistrationData.personal_information.linkedin.name}
          mainLabel={ReviewerRegistrationData.personal_information.linkedin.mainLabel}
          name={forms.personal_information.linkedin}
          secondaryLabel={ReviewerRegistrationData.personal_information.linkedin.secondaryLabel}
          placeholder={ReviewerRegistrationData.personal_information.linkedin.placeholder}
          variant={!!errors[forms.personal_information.linkedin] ? 'error' : 'default'}
          value={watch(forms.personal_information.linkedin) as string}
          onChange={handleChangeLinkedin}
          helperText={errors[forms.personal_information.contact_number]?.message as string}
        />

        <div
          className={clsx(
            'grid grid-cols-1 place-content-start place-items-start gap-[0.75rem]',
            'w-full'
          )}>
          <Typography
            variant={'Regular'}
            size={'S'}
            color={'text-grayscale-70'}
            className={'text-left'}>
            {ReviewerRegistrationData.personal_information.interest.mainLabel}
          </Typography>
          <div
            className={clsx(
              'grid grid-cols-1 place-content-start place-items-start gap-[0.75rem]',
              'w-full'
            )}>
            {ReviewerRegistrationData.personal_information.interest.options.map((option) => (
              <div
                className={clsx(
                  'grid grid-flow-col items-center content-center justify-start justify-items-start gap-[0.875rem]',
                  'w-full'
                )}>
                <Checkbox
                  id={option.id}
                  name={option.id}
                  checked={
                    !!(
                      watch(forms.personal_information.interest) as { id: string; name: string }[]
                    ).filter((item) => item.id === option.id).length
                  }
                  onChange={() => handleSelect(option)}
                />
                <Typography variant={'Regular'} size={'XS'} color={'text-grayscale-70'}>
                  {option.name}
                </Typography>
              </div>
            ))}
          </div>
        </div>

        <Button
          disabled={isButtonDisabled}
          variant={'primary'}
          fullWidth
          onClick={handleRegisterReviewer}>
          {ReviewerRegistrationData.personal_information.actions.primary}
        </Button>
      </div>
      {/* end form */}
    </div>
  );
};
